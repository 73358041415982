import { PasswordConfig } from "@energylab/shared-components/constants/config/passwordConfig";

export const passwordConfig: PasswordConfig = {
    requireOldPassword: true,
    minLength: 8,
    requireLetter: true,
    requireNumber: true,
    requireSpecialCharacter: true,
    requireLowerCase:true,
    requireUpperCase:true,
    allowedSpecialCharacters: [
        "~",
        "!",
        "@",
        "#",
        "$",
        "%",
        "^",
        "&",
        "*",
        "(",
        ")",
        "_",
        "-",
        "+",
        "=",
        "{",
        "}",
        "[",
        "]",
        "\\",
        "|",
        ":",
        ";",
        "\"",
        "'",
        "<",
        ">",
        ",",
        ".",
        "?",
        "/"
    ],
    lockOut: {
        enabled: false,
        time: 0,
        attempts: 10
    }
};
