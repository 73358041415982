import { minYearOld } from "environment";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { FormStructureScheme, FormElement } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { compareToFirstPasswordCreator } from "@energylab/shared-components/utils/compareToFirstPassword";
import { getPasswordRules } from "@energylab/shared-components/utils/passwordRule";
import * as React from "react";
import { passwordConfig } from "./passwordConfig";
import { sex } from "./sex";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";


export const personalNumber: FormElement = {
    title: "form.personalNumber",
    type: "text",
    property: "personalNumber",
    required: true,
    defaultValue: "",
    validationRules: [{
        message: <FormatMessage id="error.number.type" />,
        validator: (rule, value, callback) => {
            const reducedSpaces = value.replaceAll(" ", "");
            const isNumber = /^\d+$/.test(reducedSpaces);
            return (isNumber || !value) ? callback() : callback("error");
        }
    }]
};

export const location: FormElement = {
    title: "form.location",
    type: "select",
    property: "location",
    required: false,
    options: [
        {
            title: <FormatMessage id="location.corporateAffairs" />,
            value: "corporateAffairs"
        },
        {
            title: <FormatMessage id="location.corporateProjecten" />,
            value: "corporateProjecten"
        },
        {
            title: <FormatMessage id="location.preventie" />,
            value: "preventie"
        },
        {
            title: <FormatMessage id="location.interneAudit" />,
            value: "interneAudit"
        },
        {
            title: <FormatMessage id="location.communicatie" />,
            value: "communicatie"
        },
        {
            title: <FormatMessage id="location.secretariaat-generaal" />,
            value: "secretariaat-generaal"
        },
        {
            title: <FormatMessage id="location.hr" />,
            value: "hr"
        },
        {
            title: <FormatMessage id="location.klantendienst" />,
            value: "klantendienst"
        },
        {
            title: <FormatMessage id="location.netbeheer" />,
            value: "netbeheer"
        },
        {
            title: <FormatMessage id="location.netuitbating" />,
            value: "netuitbating"
        },
        {
            title: <FormatMessage id="location.supplyChain" />,
            value: "supplyChain"
        },
        {
            title: <FormatMessage id="location.databeheer" />,
            value: "databeheer"
        },
        {
            title: <FormatMessage id="location.financieelBeheer" />,
            value: "financieelBeheer"
        },
        {
            title: <FormatMessage id="location.strategie" />,
            value: "strategie"
        },
        {
            title: <FormatMessage id="location.atrias" />,
            value: "atrias"
        }
    ]
};

export const region: FormElement = {
    title: "form.region",
    type: "select",
    property: "region",
    options: [
        {
            title: <FormatMessage id="region.bedrijfsvoering" />,
            value: "bedrijfsvoering"
        },
        {
            title: <FormatMessage id="region.antwerpen" />,
            value: "antwerpen"
        },
        {
            title: <FormatMessage id="region.brugge-middenkust" />,
            value: "brugge-middenkust"
        },
        {
            title: <FormatMessage id="region.hageland-dijle" />,
            value: "hageland-dijle"
        },
        {
            title: <FormatMessage id="region.ijzer-mandel" />,
            value: "ijzer-mandel"
        },
        {
            title: <FormatMessage id="region.kempen" />,
            value: "kempen"
        },
        {
            title: <FormatMessage id="region.leie-schelde" />,
            value: "leie-schelde"
        },
        {
            title: <FormatMessage id="region.limburgNoord" />,
            value: "limburgNoord"
        },
        {
            title: <FormatMessage id="region.limburgZuid" />,
            value: "limburgZuid"
        },
        {
            title: <FormatMessage id="region.mechelen" />,
            value: "mechelen"
        },
        {
            title: <FormatMessage id="region.schelde-waas" />,
            value: "schelde-waas"
        },
        {
            title: <FormatMessage id="region.schelde-lieve" />,
            value: "schelde-lieve"
        },
        {
            title: <FormatMessage id="region.zenne-dender" />,
            value: "zenne-dender"
        },
        {
            title: <FormatMessage id="region.netuitbating-NVT" />,
            value: "netuitbating-NVT"
        }
    ],
    required: false
};

export const registerStructure: FormStructureScheme = {
    type: "steps",
    steps: [
        {
            title: "register.profilePoliciesStepTitle",
            status: "current",
            icon: "",
            formElements: []
        },
        {
            title: "register.basicStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.email",
                    type: "text",
                    property: "email",
                    required: true,
                    validationRules: [{
                        type: "email",
                        message: <FormatMessage id="auth.error.invalidEmail" />
                    }]
                },
                {
                    title: "form.password",
                    type: "password",
                    property: "password",
                    validationRules: getPasswordRules(passwordConfig)
                },
                {
                    title: "register.passwordInfo",
                    type: "p",
                    property: "passwordInfo",
                    hide: true
                },
                {
                    title: "form.confirmPassword",
                    type: "password",
                    property: "passwordConfirm",
                    validationRules: [
                        {
                            validatorBuilder: compareToFirstPasswordCreator,
                            message: <FormatMessage id="auth.error.notEqualPassword" />
                        }
                    ],
                    required: true
                }
            ]
        },
        {
            title: "register.profileStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "form.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                },
                {
                    title: "form.sex",
                    type: "radio",
                    options: sex,
                    property: "sex",
                    required: true
                },
                {
                    title: "form.birthDate",
                    type: "date",
                    property: "birthDate",
                    required: true,
                    validationRules: [
                        {
                            validatorBuilder: checkMinBirthDateCreator(minYearOld)
                        }
                    ]
                },
                {
                    title: "form.nationality",
                    type: "select",
                    options: nationalities.map(nat => (
                        {
                            title: <FormatMessage id={`nationality.${nat.code}`} />,
                            value: nat.code
                        }
                    )),
                    property: "nationality",
                    required: true
                },
                location,
                region,
                personalNumber
            ]
        },
        {
            title: "register.profileStepExtraTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.avatar",
                    type: "uploadAvatar",
                    property: "avatar"
                },
                {
                    title: "form.useNickname",
                    type: "checkbox",
                    property: "useNickname"
                },
                {
                    title: "form.useNickname",
                    type: "text",
                    property: "nickname",
                    showWhen: {
                        property: "useNickname",
                        valueIs: true
                    },
                    required: true
                },
                {
                    title: "form.showActivities",
                    type: "checkbox",
                    property: "showActivities"
                }
            ]
        }
    ]
};

