import { RedirectType } from "@energylab/shared-components/routes/routes";
import {lazy} from "react";


const DashboardOverviewContainer = lazy(() => import("@energylab/shared-components/modules/dashboard/dashboardOverview/dashboardOverviewContainer"));
const ContentContainer = lazy(() => import("@energylab/shared-components/modules/pages/content/contentContainer"));
const ChallengesContainer = lazy(() => import("@energylab/shared-components/modules/challenges/challenges/challengesContainer"));
const ChallengeDetailContainer = lazy(() => import("@energylab/shared-components/modules/challenges/challengeDetail/challengeDetailContainer"));
const AmbassadorInfoContainer = lazy(() => import("@energylab/shared-components/modules/ambassadors/ambassadorInfo/ambassadorInfoContainer"));
const AmbassadorOverviewContainer = lazy(() => import("@energylab/shared-components/modules/ambassadors/ambassadorOverview/ambassadorOverviewContainer"));
const AmbassadorRedirectCheckContainer = lazy(() => import("@energylab/shared-components/modules/ambassadors/ambassadorRedirectCheck/ambassadorRedirectCheckContainer"));
const AmbassadorContainer = lazy(() => import("@energylab/shared-components/modules/ambassadors/ambassador/ambassadorContainer"));
const ArticleDetailContainer = lazy(() => import("@energylab/shared-components/modules/news/articleDetail/articleDetailContainer"));
const ArticlesContainer = lazy(() => import("@energylab/shared-components/modules/news/articles/articlesContainer"));
const ArticleTypeContainer = lazy(() => import("@energylab/shared-components/modules/news/articleType/articleTypeContainer"));
const BenefitDetailContainer = lazy(() => import("@energylab/shared-components/modules/benefits/benefits/benefitDetail/benefitDetailContainer"));
const ContentCardOverviewContainer = lazy(() => import("@energylab/shared-components/modules/benefits/contentCardOverview/contentCardOverviewContainer"));

export const ROUTES = [
    {
        locales:{
            nl: "home"
        },
        element: <DashboardOverviewContainer />,
        path: "dashboard"
    },
    {
        locales: {
            nl: "batterijen"
        },
        path: "about",
        element: <ContentContainer type="about" />
    },
    {
        locales:{
            nl: "uitdagingen"
        },
        path: "challenges",
        children: [
            {
                path: ":challengeId/actions/:teamAction",
                element: <ChallengeDetailContainer />
            },
            {
                index: true,
                element: <ChallengesContainer />
            }
        ]
    },
    {
        locales: {
            nl: "warmlopers"
        },
        path: "ambassadors",
        children: [
            {
                path: ":id/:tab",
                element: (
                    <AmbassadorContainer
                        defaultTab="info"
                        tabRoute="ambassadors/:id/:tab"
                        tabs={[
                            {
                                tabName: "info",
                                navbarTextId: "ambassador.info",
                                component: (p) => <AmbassadorInfoContainer {...p} />
                            },
                            {
                                tabName: "overview",
                                navbarTextId: "ambassador.overview",
                                component: (p) => <AmbassadorOverviewContainer {...p} />
                            }
    
                        ]}
                        title="ambassador.title"
                    />
                )
            },
            {
                index: true,
                element: <AmbassadorRedirectCheckContainer />
            }
        ]
    },
    {
        locale: {
            nl: "blog"
        },
        path: "news",
        children: [
            {
                path: "article/:contentId",
                element: <ArticleDetailContainer />
            },
            {
                path: "tags/:contentTagId",
                children: [
                    {
                        path: ":contentId",
                        element: <ArticleDetailContainer />
                    },
                    {
                        index: true,
                        element: <ArticleTypeContainer />
                    }
                ]
            },
            {
                index: true,
                element: <ArticlesContainer />
            }
        ]
    },
    {
        locales:{
            nl: "voordelen"
        },
        path: "benefits",
        children: [
            {
                path: ":benefitId",
                element: <BenefitDetailContainer />
            },
            {
                index: true,
                element: (
                    <ContentCardOverviewContainer
                        contentType="benefits"
                        introKey="benefits"
                        metaDataKey="benefits"
                    />
                )
            }
        ]
    }
]

export const REDIRECTS: RedirectType[] = [];
