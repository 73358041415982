/* eslint-disable import/no-extraneous-dependencies */
import "@energylab/shared-components/assets/icons/enlab.font";
import { AppWrapper } from "@energylab/shared-components/components/appWrapper/appWrapper";
import { ANTD_THEME, theme } from "./style/theme";
import { createInitialStore } from "./utils/createInitialStore";
import React from "react";
import { translations as getTranslations } from "./translations/translations";

const storeOverrides = createInitialStore();
const translations = getTranslations();

const AppComponent: React.FC = () => {

    return (
        <AppWrapper
            translations={translations}
            initialStore={storeOverrides}
            theme={{
                overrides: theme,
                antd: ANTD_THEME
            }}
        />
    );
};

export const App =  AppComponent;
