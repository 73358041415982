/* eslint-disable global-require */
import * as environment from "environment";
import { contactConfig } from "@energylab/shared-components/constants/config/contactConfig";
import { galleryConfig } from "@energylab/shared-components/constants/config/galleryConfig";
import { resetPasswordConfig } from "@energylab/shared-components/constants/config/resetPasswordConfig";
import { whiteListSignUpConfig } from "@energylab/shared-components/constants/config/whiteListSignUpConfig";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { ConfigReducerState } from "@energylab/shared-components/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { providers } from "./constants/config/providers";
import { REDIRECTS, ROUTES } from "./routes";
import { passwordConfig } from "./constants/config/passwordConfig";
import { registerStructure } from "./constants/config/registerStructure";
import { profileConfig } from "./constants/config/profileConfig";
import { getRoutes } from "@energylab/shared-components/routes";
import { navbarConfig } from "./constants/config/navbarConfig";
import { tourConfig } from "./constants/config/tourConfig";

const locales = ["nl"];
const defaultLocale = "nl";
// TODO: Change company logo
export const companyLogo = "https://res.cloudinary.com/energylab/image/upload/v1636366401/eandis/Fluvius_Logo_POS_RGB_01.svg";

export const config: ConfigReducerState = {
    navbarConfig,
    password: passwordConfig,
    mustAcceptPolicies: ["privacy", "disclaimer", "cookyPolicy"],
    profileConfig,
    hideSocialLogin: true,
    registerConfig: {
        backgroundImage: "https://res.cloudinary.com/energylab/image/upload/v1638280574/Fluvius/20211130_Update_Loginscreen_Fluvius.png",
        disableColorOverlay: true,
        requireEmailValidation: true,
        structure: registerStructure
    },
    resetPasswordConfig,
    galleryConfig,
    ambassadorSettingsConfig: {
        enableRelive: false,
        enableComments: true
    },
    socialConfig: {
        disableInstagram: false,
        disableTwitter: false,
        disableFacebook: false,
        disableLinkedIn: false,
        disableOnChallenCard: false,
        hideFacebookOnCommunity: false,
        hideTwitterOnCommunity: false,
        hideInstagramOnCommunity: false,
        hideLinkedInOnCommunity: true
    },
    dashboardPlacesConfig: {
        articles: [],
        benefits: [],
        challenges: [],
        events: [],
        gallery: [],
        homePage: ["dashboard"],
        leaderBoard: [],
        mySpace: [],
        mySpaceActivities: [],
        profile: [],
        webShop: [],
        contact: ["contact_header"]
    },
    ambassadorImage: "",
    auth: {
        facebookRedirect: environment.auth.facebook,
        googleRedirect: environment.auth.google,
        anonymousToken: environment.anonymousToken
    },
    commentsConfig: {
        disabled: false,
        autoShowSubComments: false,
        autoShowInput: false
    },
    eventsConfig: {
        defaultView: "list",
        viewOptions: []
    },
    contentConfig: {
        showAuthor: false,
        useThemes: true,
        previewType: "card",
        enablePageBanner: true,
        pageBannerImage: "https://res.cloudinary.com/energylab/image/upload/v1561558218/generic-v2/bannerimage.jpg",
        pageBannerOverlay: true,
        alignment: "left"
    },
    contactConfig: {
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [],
        siteKey: contactConfig.siteKey
    },
    companyLogo,
    companyName: "Fluvius",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig: {
        enabled: false,
        coinName: "coin"
    },
    likesConfig: {
        disabled: false
    },
    webshopConfig: {
        enabled: false,
        shopName: ""
    },
    notificationsConfig: {
        enabled: false
    },
    providers,
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig: {
        locales,
        defaultLocale,
        routes: {
            private: getRoutes(ROUTES)
        },
        redirects: REDIRECTS,
        hideNavigationWhenLoggedOut: true
    },
    nationalities,
    selectedLanguage: getBrowserLocale(defaultLocale, locales),
    seo: {
        pageTitle: "Fluvius",
        title: {
            nl: "Fluvius",
            fr: "Fluvius",
            en: "Fluvius"
        },
        description: {
            nl: "",
            fr: "",
            en: ""
        },
        image: "",
        site: ""
    },
    uploadPreset: "fluvius",
    newUsersConfig: {
        enabled: false,
        hasListOfNewUsers: false
    },
    leaderboardConfig: {
        pageSize: 10
    },
    socialShareConfig: {
        enabled: true,
        copyClipboardEnabled: false,
        shareWithEmail: {
            enabled: false
        }
    },
    whiteListSignUpConfig: {
        enabled: false,
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [],
        siteKey: whiteListSignUpConfig.siteKey
    },
    tourConfig
};
